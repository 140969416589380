/* eslint "react/jsx-pascal-case": "off" */
import React, { useState, useEffect } from 'react'
import { navigate, Link } from 'gatsby'
import useLocale from '../hooks/useLocale'
const windowGlobal = typeof window !== 'undefined' && window

import tw, { css, styled } from 'twin.macro'

import Layout from '../components/layout'
import Meta from '../components/meta'
/** Styles with Emotion & Tailwindcss */
const Container = tw.div`mx-auto py-8 w-full lg:w-11/12 flex flex-col md:flex-row`
const LeftContent = styled.div`
	${tw`flex flex-col text-base py-0 md:py-8 px-4 lg:px-10 w-full md:w-6/12 my-6 md:my-20 items-center`}
`
const Head = tw.h1`font-bold text-10xl lg:text-11xl font-display`
const SubText = tw.p`font-display text-7xl mb-2`
const SorryText = tw.p`text-2xl font-display text-mineral-teal text-center md:w-full lg:w-10/12 mx-auto`
const ButtonsContainer = tw.div`flex flex-col sm:flex-row mt-10`
const BackHome = styled(Link)`
	${tw`bg-yellow-600 hover:bg-yellow-500 uppercase font-display text-base lg:text-lg px-4 py-2 rounded font-bold mb-2 sm:mb-0 mr-0 sm:mr-2 text-center`}
`
const Previous = tw.p`cursor-pointer bg-yellow-600 hover:bg-yellow-500 uppercase font-display text-base lg:text-lg px-4 py-2 rounded font-bold ml-0 sm:mr-2 text-center`
const RightContent = styled(LeftContent)`
	${tw`justify-center`}
`


const NotFoundPage = () => {

	const [pathname, setPathName] = useState('')
	useEffect(() => {
		const path = windowGlobal.location.pathname
		setPathName(path)
	}, [])
	const { globalLocale } = useLocale(pathname)
	return (
		<Layout>
			<Meta title="404: Not found" />
			<Container>
				<LeftContent>
					<Head>404</Head>
					<SubText>ERROR</SubText>
					<SorryText>
						Sorry. We couldn't find the page you&apos;re looking for.
					</SorryText>
					<ButtonsContainer>
						<BackHome to={globalLocale === 'en-AU' ? '/' : `/${globalLocale }`}>Back to homepage</BackHome>
						<Previous onclick={() => navigate(-1)}>Previous Page</Previous>
					</ButtonsContainer>
				</LeftContent>

				<RightContent>
					<img
						src="https://images.ctfassets.net/ftcnv5up9dxv/6aQMV04P6TOYDO5B2W9PF7/88ef05f00d467a6208ebed8d4dd8b154/404PageNotFound_UnPlugged_M600.png"
						alt=""
					/>
				</RightContent>
			</Container>
		</Layout>
	)
}
export default NotFoundPage
